import React from 'react';
import {Languages} from "../languages";
import Configs from "../configs/global";

export const getCurrentLanguage = () => {
	// const LANG = localStorage.getItem('lang');
	const LANG='FA'
	if (LANG === 'FA' || LANG === 'EN') return LANG;
	localStorage.setItem('lang', Configs.DEFAULT_LANGUAGE);
	return Configs.DEFAULT_LANGUAGE
};

export const Lang = props => {
	const CurrentLanguage = Languages[getCurrentLanguage()];
	return <React.Fragment>{CurrentLanguage[props.id] || props.id}</React.Fragment>;
};

export const lang = id => {
	const CurrentLanguage = Languages[getCurrentLanguage()];
	return CurrentLanguage[id] || id;
};

export const setLanguage = lang => {
	localStorage.setItem('lang', lang);
	window.location.reload();
};
