const HOST = window.location.hostname;
let Configs = {};

const CL = (txt, color) => console.log(`%c ${txt}`, `color: ${color};font-size: 20px;`);

/*
 * API: 	API Base URL
 * IS_PRODUCTION: Boolean of is production mode
 * DEFAULT_LANGUAGE: Set default language [FA or EN]
 *
 *
 *
 *
 * */

if (HOST === "https://admin.hussainlibrary.com/") {
    CL("PRODUCTION", "red");
    Configs = {
        API: "https://api.hussainlibrary.com/api/v1/",
        IS_PRODUCTION: true,
        DEFAULT_LANGUAGE: "EN",
    };
} else {
    CL("DEVELOPMENT", "blue");
    Configs = {
        API: "https://api.hussainlibrary.com/api/v1/",
        IS_PRODUCTION: false,
        DEFAULT_LANGUAGE: "EN",
    };
}

export default Configs;
