//Please add key to all language

export default {
	//Global Variables
	'title': 'My Panel :: ',


	//Navbar and Routing
	'nav.Dashboard': 'Dashboard',
	'nav.Home': 'Home',
	'nav.Login': 'Login',

	//Sidebar
	'sidebar.ChangePassword': 'Change Password',
	'sidebar.UpdateProfile': 'Update Profile',
	'sidebar.Logout': 'Logout',
	'sidebar.Language': 'Language',
}
