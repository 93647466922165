//Please add key to all language

export default {
	//Global Variables
	'title': 'پنل من :: ',

	//Navbar
	'nav.Dashboard': 'پیشخوان',
	'nav.Home': 'خانه',

	//Sidebar
	'sidebar.ChangePassword': 'بروزرسانی پروفایل',
	'sidebar.Logout': 'خروج',
	'sidebar.Language': 'زبان',
}
